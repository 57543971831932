<!-- =========================================================================================
    File Name: LockScreen.vue
    Description: Lock Screen Page
    ----------------------------------------------------------------------------------------
    Item Name: Legal Board Services
      Author: Enkompass
    Author URL: https://www.enkompass.net
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row no-gutter">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/lock-screen.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Welcome to Legal Board Services!</h4>
                                    <p class="mb-4">Let's get connected with Trello.</p>
                                </div>
                                <div class="flex justify-between flex-wrap">
                                  <vs-button @click="connectTrello">Connect Trello</vs-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import moduleTodo from '@/store/todo/moduleTodo.js'
import amplify from '@/amplify/auth.js'

import router from '@/router'

export default {
  data () {
    return {
      trelloUrl: '',
      firmName: '',
      encryptionKey: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.firmName !== ''
    }
  },
  created () {
    this.$store.registerModule('todo', moduleTodo)

    this.$vs.loading()

    amplify.getCurrentSession()
      .then(resp => {
        this.$vs.loading.close();
        if(resp.userProfile.data.setUpTrello){
          this.trelloUrl = resp.userProfile.data.trelloUrl;
          console.log(resp.userProfile.data.trelloUrl);
        } else {
          //Set setUpTrello as false
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          userInfo.setUpTrello = false;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          router.push(router.currentRoute.query.to || '/').catch(err => {});
        }
      })
      .catch(err => { location.reload() })

    if(this.$store.state.auth.failedFirmCheckPoint() == false){
      router.push(router.currentRoute.query.to || '/').catch(err => {})
    }
  },
  methods: {
    connectTrello($event) {
      var win = window.open(this.trelloUrl, 'trelloWindow', 'width=1024, height=768, top=100, left=200, toolbar=1');
      var timer = setInterval(function() {
        if(win.closed) {
          clearInterval(timer);
          location.reload();
          }
        }, 1000);
    }
  }
}
</script>
